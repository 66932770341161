import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ObservationModal.css';
import StudentSelectionModal from './StudentSelectionModal';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

function ObservationModal({ onClose, currentClass, students, onObservationCreated, selectedStuds}) {
  const [assesmentContent, setAssesmentContent] = useState('');
  const [showStudentSelection, setShowStudentSelection] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState(selectedStuds);
  const [allStudents, setAllStudents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  const handleAddStudents = () => {
    setShowStudentSelection(true);
  };

  const handleStudentSelection = (students) => {
    setSelectedStudents(students);
    setShowStudentSelection(false);
  };

  useEffect(() => {
    setAllStudents(students.map(student => ({
      ...student,
      isSelected: student.isSelected || false,
    })));
  }, [students]);

  const handleStudentToggle = (id) => {
    setAllStudents(allStudents.map(student =>
      student.id === id ? { ...student, isSelected: !student.isSelected } : student
    ));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadFile = async () => {
    if (!selectedFile) return null;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.post(`${apiUrl}/api/upload/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      return response.data.id; // Return the file ID
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const fileId = await uploadFile();

    if (!fileId && selectedFile) {
      setLoading(false); // Stop loading if file upload fails
      return; // Stop submission if the file failed to upload and a file was selected
    }

    const selectedStudentIds = selectedStudents.map(student => student.id);

    let observationData = {
      name: assesmentContent,
      date: selectedDate,
      classes_id: currentClass.id,
      type: 'observation',
      category_id: 0,
      grading_type: 0,
      assesment_content: JSON.stringify({students:selectedStudentIds, due_date: selectedDate}),
      students: selectedStudentIds,
    };
    if (fileId && fileId.length > 0) {
      observationData = {
        ...observationData,
        attachment_url: `${fileId}`
      }
    }

    try {
      const token = localStorage.getItem('jwtToken');
      await axios.post(`${apiUrl}/api/tasks`, observationData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      onObservationCreated(); // Optionally refresh data
      onClose();
    } catch (error) {
      console.error('Error creating observation:', error);
      alert('Failed to create observation. Please try again.');
    } finally {
      setLoading(false); // Stop loading after the request is done
    }
  };

  return (
    <div className="modal-overlay-observation">
      <div className="modal-content-observation">
        <h2 style={{ width: "50%" }}>Add Observation</h2>
        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
            <label style={{ paddingRight: "20px", fontSize: "15px" }}>
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                required
                className="date-input"
                disabled={loading} // Disable while loading
              />
            </label>
            <button
              type="button"
              className="add-students-button"
              style={{ width: "45%" }}
              onClick={handleAddStudents}
              disabled={loading} // Disable while loading
            >
              {selectedStudents.length > 0
                ? `${selectedStudents.length} Students Selected`
                : "Add Students"}
            </button>
          </div>

          <label style={{ paddingRight: "20px", marginTop: "10px", fontSize: "15px" }}>
            Comment:
            <textarea
              value={assesmentContent}
              onChange={(e) => setAssesmentContent(e.target.value)}
              required
              className="textarea-input"
              disabled={loading} // Disable while loading
            />
          </label>
          <div>Attachments:</div>
          <label className="upload-label" style={{maxWidth:"80%", width:"80%"}} >
            <input type="file" className="upload-input" onChange={handleFileChange} disabled={loading} />
            {selectedFile ?  <span style={{whiteSpace:"nowrap"}}>{selectedFile.name}</span> :  <span> Upload Files</span>}
          </label>
          <div className="modal-actions">
            <button type="button" className="cancel-button" onClick={onClose} disabled={loading}>Cancel</button>
            <button type="submit" className="create-button" disabled={loading}>
              {loading ? 'Saving...' : 'Create'} {/* Show saving state */}
            </button>
          </div>
          {showStudentSelection && (
            <StudentSelectionModal
              onClose={() => setShowStudentSelection(false)}
              onSelect={handleStudentSelection}
              students={students}
              name={"Observe"}
              alreadySelected={selectedStudents}
            />
          )}
        </form>
        {loading && <div className="spinner"></div>} {/* Spinner */}
      </div>
    </div>
  );
}

export default ObservationModal;
