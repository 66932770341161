import React, { useState } from 'react';
import './StudentSelectionModal.css';

function StudentSelectionModal({ onClose, onSelect, students, name, alreadySelected }) {
  const [selectedStudentIds, setSelectedStudentIds] = useState(alreadySelected.map(student => student.id));
  const [isAllSelected, setIsAllSelected] = useState(false);

  const toggleStudentSelection = (studentId) => {
    setSelectedStudentIds((prevSelected) =>
      prevSelected.includes(studentId)
        ? prevSelected.filter((id) => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      setSelectedStudentIds([]);
    } else {
      const allStudentIds = students.map((student) => student.id);
      setSelectedStudentIds(allStudentIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleConfirmSelection = () => {
    const selectedStudents = students.filter((student) =>
      selectedStudentIds.includes(student.id)
    );
    onSelect(selectedStudents);
    onClose(selectedStudents);
  };

  return (
    <div className="modal-overlay">
      <div className="student-selection-modal">
        <div className="modal-header">
          <h3>Select Students to {name}</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-content-students">
          <p>
            {selectedStudentIds.length} Students Selected
          </p>
          <table className="student-table">
            <thead>
              <tr>
                <th>           <div className="select-all-container">
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={handleToggleAll}
            />
          </div> </th>
                <th>Name</th>
                <th>Student ID</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedStudentIds.includes(student.id)}
                      onChange={() => toggleStudentSelection(student.id)}
                    />
                  </td>
                  <td>{student.name}</td>
                  <td>{student.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button className="confirm-button" onClick={handleConfirmSelection}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default StudentSelectionModal;
